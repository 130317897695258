import request from '@/utils/request'
// 获取配置
export function GetArticle(query) {
  return request({
    url: '/api/index/getConfig',
    method: 'get',
    params: query
  })
}
export function getCat(data) {
  return request({
    url: '/api/index/getCat',
    method: 'post',
    data: data
  })
}
//获取分类内容
export function getNav(query) {
  return request({
    url: '/api/index/getIndexNav',
    method: 'post',
    params: query
  })
}
//获取白金赞助
export function guanggao(query) {
  return request({
    url: '/api/index/getAd',
    method: 'post',
    params: query
  })
}
//资讯
export function getNewsRand(data) {
  return request({
    url: '/api/index/getNewsRand',
    method: 'post',
    data: data
  })
}
//导航新增
export function setBrowse(query) {
  return request({
    url: '/api/index/setBrowse',
    method: 'post',
    params: query
  })
}
//详情
export function getDetails(query) {
  return request({
    url: '/api/index/getNav_Info',
    method: 'post',
    params: query
  })
}
//顶部和底部导航 还有热门链接
export function gethead(query) {
  return request({
    url: '/api/index/gethead',
    method: 'post',
    params: query
  })
}

//根据条件获取导航
export function getAllNav(query) {
  return request({
    url: '/api/index/getAllNav',
    method: 'post',
    params: query
  })
}
//获取详情里面的资讯
export function getNews(query) {
  return request({
    url: '/api/index/getNews',
    method: 'post',
    params: query
  })
}
//获取资讯详情
export function getNewsInfo(query) {
  return request({
    url: '/api/index/getNewsInfo',
    method: 'post',
    params: query
  })
}
//搜索
export function getNavBySearch(query) {
  return request({
    url: '/api/index/getNavBySearch',
    method: 'post',
    params: query
  })
}
//获取文章分类
export function getFlNews(data) {
  return request({
    url: '/api/index/getFlNews',
    method: 'post',
    data: data
  })
}
//导航日浏览量查询
export function getDegree(query) {
  return request({
    url: '/api/index/getDegree',
    method: 'post',
    params: query
  })
}
//根据分类获取导航
export function getNavIndex(query) {
  return request({
    url: '/api/index/getNav',
    method: 'post',
    params: query
  })
}
